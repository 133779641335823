<template>
	<app-layout class="inner-dialog">
		<div slot="header">
			<el-input placeholder="请输入姓名或手机号查询" style="width: 40%" v-model.trim="apiParams.keyWork" @keyup.enter.native="filter">
				<i slot="suffix" class="el-input__icon el-icon-search search_icon" @click="filter"></i>
			</el-input>
		</div>
		<el-table
			:data="tableDatas"
			ref="Table"
			class="radio-table tableBackground"
			tooltip-effect="dark"
			:row-key="getRowKey"
			v-loading="pageLoading"
			style="width: 100%"
			@row-click="confirm"
		>
			<!--<el-table-column
        type="selection"
        :reserve-selection="true"
        width="60"
        align="center"
      ></el-table-column>!-->
			<el-table-column prop="staffName" label="姓名" width align="center"></el-table-column>
			<el-table-column prop="orgName" label="组织" width align="center"></el-table-column>
			<el-table-column prop="officeName" label="职位" width align="center"></el-table-column>
			<!-- <el-table-column prop="phoneNumber" label="手机号" width align="center"></el-table-column> -->
			<el-table-column prop="jobName" label="岗位" width align="center"></el-table-column>
			<!-- <el-table-column prop="jobNumber" label="工号" width align="center"></el-table-column> -->
			<!-- <el-table-column prop="mailbox" label="工作邮箱" width="200" align="center"></el-table-column> -->
		</el-table>
		<div slot="footer" style="position: relative; height: 40px; margin-top: 10px">
			<el-pagination
				background
				:current-page="apiParams.pageNo"
				@current-change="handleCurrentChange"
				:page-size="apiParams.pageSize"
				layout="prev, pager, next"
				style="position: absolute; right: 0"
				:page-count="totalPage"
			>
			</el-pagination>
			<!--<div class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="confirm"
        >确认</el-button>
      </div>!-->
		</div>
	</app-layout>
</template>
<script>
import { getStaffList } from '../../../api/system';
import PageMixin from '../../../mixins/pageQuery';
export default {
	mixins: [PageMixin],
	data() {
		return {
			apiParams: {
				pageNo: 1,
				pageSize: 40,
				keyWork: ''
			},
			pageLoading: false,
			tableDatas: [],
			selectList: []
		};
	},
	props: {
		filterParams: {
			type: Object,
			default: () => ({})
		},
		selectStatus: {
			type: Boolean,
			default: () => false
		},
		show: Boolean
	},
	watch: {
		show: {
			handler(val) {
				if (val) {
					this.selectList = [];
					this.$refs.Table.clearSelection();
					this.filter();
				}
			}
		}
	},
	mounted() {
		this.getPageData();
	},
	methods: {
		async getPageData() {
			this.pageLoading = true;
			try {
				await this.getEmployees();
			} finally {
				this.pageLoading = false;
			}
		},
		confirm(row) {
			if (this.selectStatus) {
				this.selectList = [row];
			} else {
				this.selectList = [{ id: row.staffId, value: row.staffName }];
			}
			this.$emit('confirm', this.selectList, row);
			this.$emit('cancel');
		},
		cancel() {
			this.$emit('cancel');
		},
		// handleSelectChange (section, row) {
		//   this.$refs.Table.clearSelection()
		//   this.$refs.Table.toggleRowSelection(row, true);
		//   if(this.selectStatus){
		//     this.selectList = [row];
		//   }else{
		//     this.selectList = [{ id: row.staffId, value: row.staffName }];
		//   }
		// },
		getRowKey(row) {
			return row.staffId;
		},
		async getEmployees() {
			return getStaffList({ request: { ...this.apiParams, ...this.filterParams } }).then((res) => {
				this.tableDatas = res.list;
				this.totalPage = res.totalPage;
			});
		}
	}
};
</script>
<style lang="scss">
.dialog-footer {
	margin-top: 14px;
	// transform: translate(5px, 40px);
	text-align: center;
}
.inner-dialog {
	.app-list-center {
		height: 800px;
	}
}
</style>
