<template>
	<div class="app-container applay" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">申请</div>
		</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<div class="sections-container">
				<el-form-item prop="performanceName" label="申请类型">
					<el-select
						style="width: 400px"
						v-model="approvalType"
						placeholder="选择申请类型"
						value-key="approvalId"
						@change="changeApprovalProperties"
						:disabled="Number($route.query.applyId) ? true : false"
					>
						<el-option v-for="(item, i) in approvalTypes" :value="item.subType" :label="item.approvalName" :key="i">
							<template v-if="item.child?.length">
								<div @click.stop>
									<el-popover placement="right" width="400" trigger="hover" ref="selectTree">
										<el-tree
											:data="item.child"
											:props="{ label: 'childApprovalName', children: 'child' }"
											@node-click="(e) => changeApprovalProperties(item.subType, e)"
										></el-tree>
										<div
											slot="reference"
											style="display: inline-flex; align-items: center; justify-content: space-between; width: 100%"
										>
											<span style="margin-right: 10px">{{ item.approvalName }}</span>
											<span>
												<i slot="reference" class="el-icon-arrow-right" style="margin-right: 10px"></i>
											</span>
										</div>
									</el-popover>
								</div>
							</template>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="staffId" label="员工" :required="true" v-if="subType === '20'">
					<div>
						<component @click="openInnerDialog()" type="text" :is="'el-button'">选择</component>
						<p>
							<el-tag v-if="staffInfo.id" closable @close="removeTag">{{ staffInfo && staffInfo.value }}</el-tag>
						</p>
					</div>
				</el-form-item>
				<el-row style="text-align: center; margin-top: -10px">
					<el-button @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" @click="submit">下一步</el-button>
				</el-row>
			</div>
		</el-form>
		<el-dialog append-to-body top="10px" width="55%" center title="员工" :visible.sync="innerDialogVisible">
			<div style="height: 80vh; overflow-y: auto">
				<table-employees
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					:filter-params="employeesParams"
					@cancel="innerDialogVisible = false"
				></table-employees>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { getApprovalType } from '../../../api/staffSystem';
import TableEmployees from '../components/table-employees.vue';

export default {
	name: 'approvalApply',
	components: { TableEmployees },
	computed: {
		query() {
			return this.$route.query;
		}
	},
	data() {
		return {
			approvalTypeId: '',
			approvalTypes: [],
			approvalType: null,
			loading: false,
			form: {},
			rules: {},
			subType: '',
			innerDialogVisible: false,
			employeesParams: { notPositive: 0 },
			activeDialogObj: null,
			staffInfo: {}
		};
	},
	methods: {
		async getApprovalType() {
			return getApprovalType().then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalTypes = res.map((val) => ({ ...val, subType: val.subType.toString() }));
				}
			});
		},
		async changeApprovalProperties(value, e) {
			const obj = this.approvalTypes.find(({ subType }) => subType === value) || {};

			const { approvalId } = obj;

			if (!['11', '12', '13', '14', '20'].includes(value)) {
				this.$router.push({
					path: '/staff/approval_apply',
					query: { approvalId, subType: value, mode: '申请审核', childSubType: e?.childSubType, childApprovalName: e?.childApprovalName }
				});
			}
			this.approvalTypeId = approvalId;
			this.subType = value;
			window.history.replaceState('', '', '/#/staff/approvalApply');
		},
		async submit() {
			if (!this.approvalTypeId) {
				this.$message({
					message: '请选择审批类型',
					type: 'warning'
				});
				return;
			}
			if (!this.staffInfo.id && this.subType === '20') {
				this.$message({
					message: '请选择员工',
					type: 'warning'
				});
				return;
			}
			let params = {};

			switch (this.subType) {
				case '11':
					this.$router.push({
						path: '/staff/orgApply',
						query: { approvalId: this.approvalTypeId, subType: this.subType }
					});
					break;
				case '8':
				case '12':
				case '13':
				case '14':
				case '20':
					params = { approvalId: this.approvalTypeId, subType: this.subType };
					if (this.subType === '20') {
						params.id = this.staffInfo.id;
						params.jobId = this.staffInfo.jobId;
					}
					this.$router.push({
						path: '/staff/changeApply',
						query: params
					});
					break;

				default:
					break;
			}
		},
		async confirmSelect(value, { jobId }) {
			if (value.length > 0) {
				if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
					if (this.$store.state.app.range.staffIds?.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds?.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				this.staffInfo = Object.assign({}, value[0], { jobId });
				this.innerDialogVisible = false;
			}
		},
		openInnerDialog() {
			this.innerDialogVisible = true;
		},
		removeTag() {
			this.staffInfo = {};
		}
	},
	mounted() {
		if (this.query) {
			const { approvalId, subType } = this.query;

			if (subType) {
				this.approvalTypeId = approvalId;
				this.subType = subType;
				this.approvalType = subType;
			}
		}
		this.getApprovalType();
	}
};
</script>
<style scoped lang="scss">
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-weight: bold;
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.section-wrap {
		margin-bottom: 20px;
	}
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.applay {
	.el-dialog__header {
		text-align: center;
	}
}
.approvalConChild {
	flex: 1;
	display: flex;
	flex-direction: row;
	// border-bottom: 1px solid #EAEDF1;;
	padding-bottom: 15px;
	.approvalConChild_head {
		margin-top: -12px;
	}
	.approvalConChild_container {
		flex: 1;
		width: 100%;
		margin-right: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}
.morye,
.approvalConChild_container {
	.block {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		.approvalConChild_container_name {
			line-height: 14px;
			color: #c8c8c8;
			font-size: 14px;
		}
		.delete {
			position: absolute;
			top: -1px;
			right: 0;
			padding: 2px;
		}
		p {
			text-align: center;
		}
		i.el-icon-arrow-right {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
		i.el-icon-plus {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
	}
}
.approvalConChild_add {
	border: 1px dashed #409eff;
	color: #409eff;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
.morye {
	::v-deep.el-dialog__header {
		text-align: center;
		// border-bottom: 1px solid #EAEDF1;
	}
	::v-deep.el-dialog__footer {
		text-align: center;
	}
	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		color: #409eff;
		letter-spacing: 0;
		line-height: 24px;
	}
}
</style>
